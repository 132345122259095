import React, { useEffect, useState } from "react";
import { graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import BlogPost from "../components/BlogPost";
import SEO from "../components/SEO";
import Avatar from "../components/Avatar";
import Share from "../components/Share";
import ArrowBack from "../../assets/images/arrow-back.svg";
import readTime from "../utils/read-time";
import useWindowDimensions from "../hooks/useWindowDimensions";

export const pageQuery = graphql`
  query PostQuery($uid: String!, $tags: [String]) {
    prismicPost(uid: { eq: $uid }) {
      type
      uid
      tags
      first_publication_date
      last_publication_date
      data {
        intro
        keywords
        description
        image {
          alt
          url
        }
        author {
          document {
            ... on PrismicTeamMember {
              data {
                name
                photo {
                  url
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        quality: 90
                        tracedSVGOptions: {
                          background: "#1e22aa"
                          color: "#333"
                        }
                        placeholder: BLURRED
                        layout: FULL_WIDTH
                      )
                    }
                  }
                }
              }
            }
          }
        }
        canonical_url {
          url
        }
        content {
          html
        }
        cover {
          alt
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                tracedSVGOptions: { background: "#1e22aa", color: "#333" }
                placeholder: BLURRED
                layout: FULL_WIDTH
              )
            }
          }
        }
        date
        image {
          alt
          url
        }
        title
        intro
      }
    }
    allPrismicPost(
      sort: { fields: data___date, order: DESC }
      limit: 2
      filter: { tags: { in: $tags }, uid: { ne: $uid } }
    ) {
      nodes {
        data {
          author {
            document {
              ... on PrismicTeamMember {
                data {
                  name
                  photo {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          quality: 90
                          tracedSVGOptions: {
                            background: "#1e22aa"
                            color: "#333"
                          }
                          placeholder: BLURRED
                          layout: FULL_WIDTH
                        )
                      }
                    }
                  }
                }
              }
            }
          }
          canonical_url {
            url
          }
          date
          description
          cover {
            alt
            url
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 90
                  tracedSVGOptions: { background: "#1e22aa", color: "#333" }
                  placeholder: BLURRED
                  layout: FULL_WIDTH
                )
              }
            }
          }
          content {
            html
          }
          title
          intro
        }
        tags
        uid
      }
    }
  }
`;

const SM_SCREEN_MAX_SIZE = 1000;

const Post = (props: any) => {
  const dimensions = useWindowDimensions();
  const PostObject = props.data.prismicPost || {};
  const RelatedPosts = props.data.allPrismicPost.nodes || [];

  const [screenSize, setScreenSize] = useState<"sm" | "lg">();

  useEffect(() => {
    if (dimensions) {
      const screenWidth = dimensions.width;

      if (screenWidth < SM_SCREEN_MAX_SIZE) {
        setScreenSize("sm");
        return;
      }
      setScreenSize("lg");
    }
  }, [dimensions]);

  return (
    <>
      <SEO
        {...props.data.prismicPost.data}
        article
        pathname={PostObject.uid}
        dateCreated={PostObject.first_publication_date}
        datePublished={PostObject.first_publication_date}
        dateModified={PostObject.last_publication_date}
      />
      <div className="hero-post">
        <div className="post-button-row">
          <div className="div-block-8 button-col">
            <Link to="/blog" replace>
              <button className="arrow-back-post">
                <img src={ArrowBack} alt="arrow back icon" />
                Back to Blog
              </button>
            </Link>
          </div>
        </div>
        <div className="boxed-post-intro-container mobile-margin">
          <h1 className="post-title blog-color-primary">
            {PostObject.data.title}
          </h1>
          <h2 className="post-topic">
            {PostObject.tags?.map((item, index) => (
              <span
                id={`post-topic-item${index + 1}`}
                key={`blog-post-topic-index-${index + 1}`}
              >
                {item}
                {index + 1 < PostObject.tags.length ? ", " : ""}
              </span>
            ))}
          </h2>
          <Avatar
            readTime={readTime(
              PostObject?.data?.content?.html?.replace(/<\/?[^>]+(>|$)/g, "")
            )}
            name={PostObject.data.author?.document.data.name}
            // date={PostObject.data.date}
            image={
              PostObject.data.author?.document.data.photo.localFile
                .childImageSharp?.gatsbyImageData
            }
          />
          <p className="post-intro blog-color-primary">
            {PostObject?.data?.intro}
          </p>
        </div>
      </div>
      <div className="post-image-container">
        <GatsbyImage
          image={
            PostObject?.data?.cover?.localFile?.childImageSharp?.gatsbyImageData
          }
          className={`post-cover post-cover-${screenSize}`}
          alt={"post-cover"}
        />
        <span className="post-cover-subtitle blog-color-primary">
          {PostObject?.data?.cover?.alt}
        </span>
      </div>
      <div className="post-content-container">
        <div
          className="post-content mobile-margin"
          dangerouslySetInnerHTML={{
            __html: PostObject.data.content.html,
          }}
        />
      </div>
      <div className="social">
        <Share />
      </div>
      {RelatedPosts?.length > 0 ? (
        <>
          <div className="related-block">
            <div className="container-partner">
              <div className="wrapper-partner">
                <div className="cap">{"// Stories you might like"}</div>
                <h1 className="heading large-3 blog-color-primary">
                  Related Articles
                </h1>
              </div>
              <div className="yellow-block" />
            </div>
          </div>
          <div className="related-post-container mobile-margin">
            <div className="blog-row no-padd">
              {RelatedPosts.map((post, index) => (
                <BlogPost
                  key={`related-blog-post-${index}`}
                  source={post.data}
                  tags={post.tags}
                  path={post.uid}
                />
              ))}
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default Post;
