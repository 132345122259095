import React from "react";
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
} from "react-share";

const getLocation = () => {
  if (typeof window !== "undefined") return window.location;
  return null;
};

const Share = () => {
  const location = getLocation();
  const href = location?.href || "";

  return (
    <div className="share-social-container">
      <span className="share-title">Share This Story</span>
      <div className="social-bar">
        <TwitterShareButton url={href} className="social-icon">
          <TwitterIcon
            size={42}
            bgStyle={{ fill: "white" }}
            iconFillColor="#0C0D44"
          />
        </TwitterShareButton>
        <FacebookShareButton url={href} className="social-icon">
          <FacebookIcon
            size={42}
            bgStyle={{ fill: "white" }}
            iconFillColor="#0C0D44"
          />
        </FacebookShareButton>
        <LinkedinShareButton url={href} className="social-icon">
          <LinkedinIcon
            size={42}
            bgStyle={{ fill: "white" }}
            iconFillColor="#0C0D44"
          />
        </LinkedinShareButton>
      </div>
    </div>
  );
};

export default Share;
